@media only screen and (min-width: 500px) {
  .widget-insiw {
    width: 200px;
    height: 300px;
  }
}

@media only screen and (min-width: 600px) {
  .widget-insiw {
    width: 200px;
    height: 500px;
  }
}

@media only screen and (min-width: 900px) {
  .widget-insiw {
    width: 600px;
    height: 600px
  }
}

@media only screen and (min-width: 1400px) {
  .widget-insiw {
    width: 1200px;
    height: 900px
  }
}

.widget {
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .widget {
    width: 400px;
  }
}

.videoWrapper {
  position: relative;
  max-height: 1550px;
}

.videoWebMWrapper {
  position: relative;
  height: 75%;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
